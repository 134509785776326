$(function () {

    /**
     * Пт
     * @type {NodeListOf<Element>}
     */
    let buttons = document.querySelectorAll('[data-copy]');
    if (buttons.length > 0) {
        buttons.forEach((button) => {
            button.addEventListener('click', e => {
                let text = button.dataset.copy;
                console.log(text)
                let inp = document.createElement('input')
                inp.value = text
                document.body.appendChild(inp)
                inp.select()

                if (document.execCommand('copy')) {
                    console.log("Done!")
                } else {
                    console.log("Failed...")
                }

                document.body.removeChild(inp)
            })
        })
    }
})

