class Accordion {
  constructor(options) {
    this.options = {
      tabSelector: '[data-accordion-tab]',
      itemSelector: '[data-accordion-item]',
      itemWrapper: '[data-accordion-tab-wrapper]',
    };
    this.options = Object.assign({}, this.options, options);
    this.links = document.querySelectorAll(this.options.tabSelector);
    this.items = document.querySelectorAll(this.options.itemSelector);
    this.blocks = [];
    this.bind();
  }

  bind() {
    this.links.forEach((link) => {
      if(!link.dataset.handled) {
        link.addEventListener('click', this.handleClick.bind(this, link));
        link.dataset.handled = 'true';

        if(this.isOpened(link)) {
          const parent = link.closest(this.options.itemSelector);
          const target = link.closest(this.options.itemSelector).querySelector(this.options.itemWrapper);
          //
          // window.onload = () => {
          //   this.openTab(parent, target);
          // };
        }
      }
    });
  }

  isOpened(link) {
    const parent = link.closest(this.options.itemSelector);
    console.log(!parent.classList.contains('_accordion_close'));
    return !parent.classList.contains('_accordion_close');
  }

  handleClick(link) {
    const parent = link.closest(this.options.itemSelector);
    const target = link.closest(this.options.itemSelector).querySelector(this.options.itemWrapper);

    if (!target.classList.contains('_accordion_close')) {
      this.closeTab(parent, target);
    } else {
      this.openTab(parent, target);
    }
  }

  closeTab(parent, target) {
    if(!target.classList.contains('_accordion_close')) {
      target.classList.add('_accordion_close');
    }
  }

  openTab(parent, target) {
    target.classList.remove('_accordion_close');
  }
}

export default Accordion;
