window.goal = function (goal) {
  if (window.Ya && window.Ya._metrika) {
    window.Ya._metrika.counter.reachGoal(goal);
  }
};


document.body.addEventListener('click', function(e) {
    let closest = e.target.closest('[data-goal-click]');
    if (closest) {
        _proceedDataGoal(closest, 'goalClick');
    }
});

function _proceedDataGoal(element, datasetAttribute) {
    let goalValue = element.dataset[datasetAttribute];
    delete element.dataset[datasetAttribute];
    goal(goalValue);
}