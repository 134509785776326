import {tns} from 'tiny-slider/src/tiny-slider';
import css from 'tiny-slider/dist/tiny-slider.css';
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper.min.css';


document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('[data-instruction-bot-slider]')) {
    instruction(
      '[data-instruction-bot-slider]',
    );
  }

  if (document.querySelector('[data-get-free-product-link-button]')){
    enableLink(document.querySelector('[data-get-free-product-link-button]'))
  }

  if (document.querySelector('[data-order-telegram-slider]')) {
    orderTelegram('[data-order-telegram-slider]')
  }

  function orderTelegram(container, left, right, arrowContainer) {
    let count = document.querySelector('[data-order-telegram-slider]').children.length;
    if (count <= 1) {
      return
    }
    let slider = tns({
      container: container,
      items: 1,
      nav: true,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      mouseDrag: true,
      controls: false,
      responsive: {
        320: {
          items: 1,
        },
        480: {
          items: 1.5,
        },
        640: {
          items: 2,
        },
        800: {
          items: 3,
        },
        1000: {
          items: 3,
        },
        1400: {
          items: 4,
        },
        2000: {
          items: 4,
        }
      }
    });
  }

  function instruction(container, left, right, arrowContainer) {
    let sliderContainer = document.querySelector(container);

    if (sliderContainer) {
      let instruction = new Swiper(container, {
        spaceBetween: 30,
        grabCursor: true,
        centeredSlides: false,
        slidesPerView: 3.7,
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.3,
            spaceBetween: 20
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1.7,
            spaceBetween: 30
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.5,
            spaceBetween: 40
          },
          800: {
            slidesPerView: 2.5,
            spaceBetween: 40
          },
          1000: {
            slidesPerView: 2.5,
            spaceBetween: 40
          },
          1400: {
            slidesPerView: 3.3,
            spaceBetween: 40
          },
          2000: {
            slidesPerView: 5.5,
            spaceBetween: 40
          }
        }
      });
    }
  }

  //кнопка работает 1 раз
  function enableLink($link) {
    $link.addEventListener('click', (e) => {
        $link.classList.add('_disable')
    })
  }

});





