import Modal from "../components/modal/modal";

class Subscribe {
    constructor() {
        this.options = {
            showModal: document.querySelector('[data-show-modal]'),
            showModalAttribute: 'data-show-modal',
            linkModal: '/telegram_bot/subscription_modal',
            linkTimeout: '/telegram_bot/subscription_timeout',
            goal: ''
        }

        this.init();
    }

    init() {
        let me = this;

        if (me.options.showModal) {
            me.showModal();
        }
    }

    // Получает задержку и открывает модальное окно
    showModal() {
        let url = document.location.href;
        let me = this;
        $.ajax({
            type: "post",
            url: me.options.linkTimeout,
            data: {'url' : url},
            success: function (data) {
                let timeout = data * 1000;

                setTimeout(() => {
                    let modal = document.querySelector('.modal__layout')
                    if (me.options.showModal.hasAttribute(me.options.showModalAttribute) && !modal) {
                        let modalTag = document.createElement('a');
                        modalTag.setAttribute('href', me.options.linkModal);
                        new Modal(modalTag, {
                            closerText: '',
                            href: me.options.linkModal,
                            classes: {
                                layout: 'modal__layout',
                                container: 'modal__container',
                                content: 'modal__content',
                                background: 'modal__bg',
                                closer: 'modal__closer',
                                loader: 'modal__loader',

                                body: 'modal-opened',
                                loading: 'modal-loading'
                            }
                        });
                    }
                }, timeout);
            },
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new Subscribe();
});
