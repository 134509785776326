function scrollDetected() {
    let headerMenu = document.querySelector('.header__menu');
    let tempScrollTop, currentScrollTop = 0;

    document.addEventListener("scroll", function () {
        currentScrollTop = window.pageYOffset;
        if (currentScrollTop !== 0) {
            if(!header.classList.contains('header_opaсity_no')){
                header.classList.add('header_opaсity_no');
            }
        } else {
            header.classList.remove('header_opaсity_no');
        }
        if(!headerMobile.classList.contains('header-mobile_open')) {
            if (tempScrollTop < currentScrollTop) {
                if (window.pageYOffset > 250 && header.classList.contains('header_visible')) {
                    header.classList.remove('header_visible');
                }
            } else if (tempScrollTop > currentScrollTop) {
                if (!header.classList.contains('header_visible')) {
                    header.classList.add('header_visible');
                }
            }
            tempScrollTop = currentScrollTop;
        }
    });
}

function openMenu() {
    if(!headerMobile.classList.contains('header-mobile_open')) {
        headerMobile.classList.add('header-mobile_open');
        header.classList.add('header_open');
        hamburger.classList.add('is-active');
        body.style.position='fixed';
        mobileMenuContainer.classList.add('active');


    } else {
        headerMobile.classList.remove('header-mobile_open');
        header.classList.remove('header_open');
        hamburger.classList.remove('is-active');
        body.style.position='static';
        mobileMenuContainer.classList.remove('active');
    }
}
document.addEventListener("DOMContentLoaded", scrollDetected);

let headerMobile = document.querySelector('.header__mobile');
let header = document.querySelector('.header');
let body = document.querySelector('body');
let hamburger = document.querySelector('.header-mobile__hamburger-container');
let mobileMenuContainer = document.querySelector('[data-mobile-menu-container]');

if(hamburger) {
    hamburger.addEventListener("click", openMenu);
}


let linkMenu =  document.querySelectorAll('[data-mobile-menu-link]');
if (linkMenu){
    for (let i = 0; i < linkMenu.length; i++) {
        linkMenu[i].addEventListener("click", function () {
            headerMobile.classList.remove('header-mobile_open');
            header.classList.remove('header_open');
            hamburger.classList.remove('is-active');
            body.style.position='static';
        });
    }
}
