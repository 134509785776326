class FilterBotProduct {
    updateContentCallback = _.debounce(() => {
        this.updateContent();
    }, 200);
    form = undefined
    list = undefined
    all = undefined

    options = {
        form: undefined, //querySelector
        list: undefined, //querySelector
    }

    constructor(options = null) {
        if (!options) {
            throw new Error('Can`t options is null')
        }
        this.options = options
        if (!this.init()) return false;
        this.bind();
    }

    init() {
        this.form = document.querySelector(this.options.form);
        this.list = document.querySelector(this.options.list);
        this.all = document.querySelector(this.options.all);
        if (!this.form || !this.list || !this.all) {
            return false;
        }
        return true;
    }

    bind() {
        this.form.addEventListener('change', (e) => {
            e.preventDefault()
            this.allButton(e)
            this.updateContentCallback()
        })

        this.all.addEventListener('click', (e) => {
            e.preventDefault()
            this.clickAll()
        })

    }

    clickAll() {
        location.href=location.href ;
    }

    allButton(e) {
        let buttonAll = this.all
        let inputs = this.form.querySelectorAll('input[type="radio"]:checked')
        if (inputs.length > 0) {
            buttonAll.classList.remove('_active');
        } else if (!buttonAll.classList.contains('_active')) {
            buttonAll.classList.add('_active')
        }

    }

    updateContent() {
        let me = this;
        let $form = $(me.options.form);
        $.ajax({
            data: $form.serialize(),
            type: "get",
            success: function (page) {
                let $page = $(page);
                let $newContent = $page.find('[data-list-product-bot]');
                let $oldContent = $('[data-list-product-bot]');
                $oldContent.html($newContent.html());

            }
        });
    }

}


let $filter = new FilterBotProduct({
    form: '[data-form-product-bot]',
    list: '[data-list-product-bot]',
    all: '[data-all-product-bot]'
})



