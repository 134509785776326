require('../scss/app.scss');

window.noZensmooth = true;
import sizes from "../scss/_settings/_settings.scss";
window.sizes = sizes;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./common/ajax_redirect.js');
require('./main.js');
require('./header.js');
require('./telergemOrder.js');
require('./subscribe.js')
require('./filterBotProduct.js')
require('./deepLink.js')
require('./source-page.js')
