import Accordion from "./common/accordion";

document.addEventListener('DOMContentLoaded', () => {
    let mainList = document.querySelector('[data-source-main-list]')
    if (mainList) {
        let accordion = new Accordion(mainList, {
            closeClass: "closed",
            disabled: false,
        });
    }

})
